angular
  .module('fg')
  .directive('attachDocumentFileUpload', [attachDocumentFileUpload]);

function attachDocumentFileUpload() {
  var directive = {
    restrict: 'A',
    require: ['?^fgForm', 'attachDocumentFileUpload'],
    scope: {
      onFileUploaded: '&',
      onChangeValue: '<'
    },
    controllerAs: 'ctrl',
    controller: [
      '$rootScope',
      '$scope',
      '$q',
      'fgFileListService',
      'pubsubService',
      'lodashService',
      'notificationService',
      'ngDialog',
      'fileService',
      'fgAttachDocumentFileListService',
      'momentService',
      function controller(
        $rootScope,
        $scope,
        $q,
        fgFileListService,
        pubsubService,
        lodashService,
        notificationService,
        ngDialog,
        fileService,
        fgAttachDocumentFileListService,
        momentService
      ) {
        const BATCH_SIZE = 5;

        var ctrl = this;
        ctrl.fileError = false;
        ctrl.ngModelCtrl = undefined;
        ctrl.fieldName = $scope.onChangeValue.name;
        ctrl.nodeId = $scope.onChangeValue.nodeId;
        ctrl.onFileChange = onFileChange;
        ctrl.uploadFiles = uploadFiles;

        function onFileChange(event) {
          if (event.target.files.length === 0) {
            return;
          }
          pubsubService.publish('FILEUPLOAD_UPLOAD_STARTED');
          event.stopPropagation();
          var filesToUpload = event.target.files;
          uploadFiles(filesToUpload);
        }

        function uploadFiles(filesToUpload) {
          uploadFileBatch(
            Array.prototype.slice.call(filesToUpload),
            0,
            BATCH_SIZE
          );
        }

        /// PRIVATE METHODS ///////////////////////////////////////////////////////////////////////
        function uploadFileBatch(files, start, batchSize) {
          if (!files || batchSize <= 0 || start >= files.length) {
            return;
          }

          var batch = files.slice(start, start + batchSize);
          var promises = batch.map(function (file) {
            return fileService.uploadAttachDocumentFile(file).then(
              function (response) {
                if (response.valid) {
                  fgAttachDocumentFileListService.addFile(
                    {
                      nodeId: ctrl.nodeId,
                      filename: file.name,
                      id: response.data,
                      key: ctrl.fieldName,
                      downloadLink: createDownloadLink(response.data),
                      size: Math.floor(file.size / 1000),
                      uploadedDate: momentService.utc().format('DD/MM/YYYY')
                    },
                    ctrl.fieldName,
                    ctrl.nodeId
                  );

                  $scope.onChangeValue.attachDocumentFileIds = $scope
                    .onChangeValue.attachDocumentFileIds
                    ? $scope.onChangeValue.attachDocumentFileIds
                    : [];
                  $scope.onChangeValue.attachDocumentFileIds.push(
                    response.data
                  );
                  pubsubService.publish('FILEUPLOAD_UPLOAD_COMPLETED');
                  notificationService.showSuccessToast(file.name + ' uploaded');
                }
              },
              function (error) {
                pubsubService.publish('FILEUPLOAD_UPLOAD_FAILED');
              }
            );
          });

          Promise.all(promises).then(() =>
            uploadFileBatch(files, start + batchSize, batchSize)
          );
        }

        /// PRIVATE METHODS ///////////////////////////////////////////////////////////////////////
        function createDownloadLink(fileId) {
          return fileService.getDownloadLink(fileId);
        }
      }
    ]
  };

  return directive;
}
