import angular from 'angular';
import { IFormModel } from '../form-controller';

angular.module('fg').directive('fgFormFields', [
  'fgConfig',
  function (fgConfig) {
    return {
      require: ['^?fgForm'],
      restrict: 'AE',
      templateUrl: 'angular-form-gen/form/form-fields/form-fields.ng.html',
      scope: {},
      link: function ($scope, $element, $attrs, ctrls) {
        const fgForm = ctrls[0];
        fgForm.model._fields = fgForm.flowFields; // We "merge" these relatively recently added fields to the existing model attribute*/
        fgForm.model.isWorkFlow = fgForm.isWorkflow;

        $scope.showDummyDoneButton = fgConfig.showDummyDoneButton;

        $scope.$watch(
          function () {
            return fgForm.model;
          },
          function (value: IFormModel) {
            $scope.form = value;
          }
        );
      }
    };
  }
]);
