angular
  .module('fg')
  .factory('fgAttachDocumentFileListService', [
    '$q',
    'lodashService',
    fgAttachDocumentFileListService
  ]);

function fgAttachDocumentFileListService($q, lodashService) {
  // A list of all the file controls
  // These are identified by a combiation of the fileId AND the nodeId
  // This is because the fileId is unique only within a flow Model NOT across flow instances.
  var _fileControls = [];
  var _form = undefined;

  var service = {
    files: [],
    addFile: addFile,
    doesFileControlExist: doesFileControlExist,
    //getFilesForStep: getFilesForStep,
    getFilesForControl: getFilesForControl,
    //getForm: getForm,
    clearFiles: clearFiles,
    removeFile: removeFile,
    //isFileListDirty: isFileListDirty,
    isFileRequired: isFileRequired,
    registerFileControl: registerFileControl,
    //getAnyFileListInvalid: getAnyFileListInvalid,
    //getFileListFileCount: getFileListFileCount,
    //getIsThisFileListInvalid: getIsThisFileListInvalid,
    //removeFileControlsForFlow: removeFileControlsForFlow,
    removeAllFileControls: removeAllFileControls,
    //removeFileControlsForFlowStep: removeFileControlsForFlowStep,
    //setFileListInvalid: setFileListInvalid,
    storeForm: storeForm
  };

  return service;

  //////////// Public API Methods
  function addFile(file, controlId, nodeId) {
    var fileList = getFileControl(controlId, nodeId);

    if (fileList) {
      fileList.isDirty = true;
      //dont add file if it already exists
      var index = fileList.files.findIndex(function (f) {
        return f.id === file.id;
      });
      if (index < 0) {
        fileList.files.push(file);
      }
    }
  }

  // CLear all files associated with the provided fieldId
  function clearFiles(fieldId) {
    lodashService.forEach(_fileControls, function (control) {
      lodashService.remove(control.files, function () {});
    });
  }

  function doesFileControlExist(controlId, nodeId) {
    var found = lodashService.find(_fileControls, function (l) {
      return l.id === controlId && l.nodeId === nodeId;
    });
    return found !== undefined;
  }

  //function getFilesForStep(nodeId) {
  //    //get all files uploaded as part of this step
  //    var stepID = getStepId(nodeId);
  //    var controlsForStep = lodashService.filter(_fileControls, function (f) {
  //        return f.nodeId == stepID;
  //    });
  //    var files = [];
  //    lodashService.forEach(controlsForStep, function (control) {
  //        files = files.concat(control.files);
  //    });
  //    return files;
  //}

  function getFilesForControl(controlId, nodeId) {
    var fileList = getFileControl(controlId, nodeId);

    if (!fileList) return [];

    return fileList.files;
  }

  //function getForm() {
  //    return _form;
  //}

  //function isFileListDirty(controlId, nodeId) {
  //    var fileList = getFileControl(controlId, nodeId);
  //    return fileList.isDirty;
  //}

  function isFileRequired(fileControlId, nodeId) {
    var fileList = getFileControl(fileControlId, nodeId);
    return fileList && fileList.isRequired;
  }

  //function getFileListFileCount(fileControlId, nodeId) {
  //    var fileList = getFileControl(fileControlId, nodeId);
  //    return fileList != null ? fileList.files.length : 0;
  //}

  function registerFileControl(controlId, stepId, nodeId) {
    var found = lodashService.find(_fileControls, function (fc) {
      if (stepId)
        //return fc.id === controlId && fc.flowInstanceId === flowInstanceId && fc.stepId === stepId;
        return fc.id === controlId && fc.stepId === stepId;

      if (nodeId) return fc.id === controlId && fc.nodeId === nodeId;
    });

    if (!found) {
      if (stepId)
        //_fileControls.push({ id: controlId, nodeId: getStepId(nodeId), flowInstanceId: flowInstanceId, files: [], isDirty: false, isRequired: required });
        _fileControls.push({
          id: controlId,
          stepId: stepId,
          files: [],
          isDirty: false
        });

      if (nodeId)
        _fileControls.push({
          id: controlId,
          nodeId: nodeId,
          files: [],
          isDirty: false
        });
    }
  }

  //function getIsThisFileListInvalid(fileControlId, nodeId) {
  //    var fileList = getFileControl(fileControlId, nodeId);
  //    return fileList && fileList.isRequired && fileList.isDirty;  //submitted how??
  //}

  //function getAnyFileListInvalid(flowInstanceId) {
  //    //why do required fields on their own not work
  //    var forFlow = lodashService.filter(_fileControls, function (c) {
  //        return c.flowInstanceId == flowInstanceId;
  //    });

  //    var invalid = lodashService.some(forFlow, function (f) {
  //        return f.invalid == true;
  //    });

  //    return invalid;
  //}

  //function setFileListInvalid(fileControlId, nodeId, flowInstanceId, invalid) {
  //    var fileControl = getFileControl(fileControlId, nodeId);
  //    fileControl.invalid = invalid;
  //    fileControl.flowInstanceId = flowInstanceId;
  //}

  //function removeFileControlsForFlow(flowInstanceId) {
  //    lodashService.remove(_fileControls, function (control) {
  //        return control.flowInstanceId == flowInstanceId;
  //    });
  //}

  function removeAllFileControls() {
    _fileControls = [];
  }

  //function removeFileControlsForFlowStep(flowInstanceId, nodeId) {
  //    lodashService.remove(_fileControls, function (control) {
  //        return control.flowInstanceId === flowInstanceId && control.nodeId === nodeId;
  //    });
  //}

  function removeFile(file, controlId, nodeId) {
    var fileList = getFileControl(controlId, nodeId);
    var index = lodashService.findIndex(fileList.files, function (f) {
      return f.id === file.id;
    });

    if (index !== -1) {
      fileList.files.splice(index, 1);
      fileList.isDirty = true;

      var schema_fileList = lodashService.find(
        _form.model.schema.fields,
        function (f) {
          return f.name == controlId;
        }
      );

      var schema_attachDocuments = schema_fileList.attachDocumentFileIds;

      var fileToRemove = lodashService.findIndex(
        schema_attachDocuments,
        function (f) {
          return f == file.id;
        }
      );

      if (fileToRemove !== -1) schema_attachDocuments.splice(fileToRemove, 1);
    }

    return $q.when();
  }

  function storeForm(form) {
    _form = form;
  }

  function getFileControl(controlId, nodeId) {
    return lodashService.find(_fileControls, function (l) {
      return l.id === controlId && l.nodeId === nodeId;
    });
  }
}
