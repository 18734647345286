angular
  .module('fg')
  .directive('fgFieldRadioButtonList', [
    'fgFieldRadioButtonListLinkFn',
    function (fgFieldRadioButtonListLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/radiobuttonlist.ng.html',
        scope: true,
        link: fgFieldRadioButtonListLinkFn
      };
    }
  ])
  .factory('fgFieldRadioButtonListLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
