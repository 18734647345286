angular
  .module('fg')
  .directive('fgFieldLookup', [
    'fgFieldLookupLinkFn',
    function (fgFieldLookupLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/lookup.ng.html',
        scope: true,
        link: fgFieldLookupLinkFn
      };
    }
  ])
  .factory('fgFieldLookupLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
