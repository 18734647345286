import { SharedAngular } from '../../@types/sharedAngular';
import angular from 'angular';

angular.module('fg').directive('fgFieldInstruction', [
  function () {
    return {
      replace: true,
      templateUrl:
        'angular-form-gen/field-templates/default/instruction.ng.html',
      scope: true,
      controllerAs: 'ctrl',
      controller: [
        '$scope',
        'pubsubService',
        'fileService',
        'validationService',
        '$sce',
        '$timeout',
        function (
          $scope: angular.IScope,
          pubsubService: SharedAngular.PubSubService,
          fileService: SharedAngular.FileService,
          validationService: SharedAngular.ValidationService,
          $sce: angular.ISCEService,
          $timeout: angular.ITimeoutService
        ) {
          const ctrl = this;

          ctrl.init = init;
          ctrl.field = null;
          ctrl.form = null;
          ctrl.instructionText = null;

          function init(field, form) {
            ctrl.field = field;
            ctrl.form = form;
            setInstructionText();
            const subscriberId = 'fgFieldInstruction' + field.name;
            // Subscribe to the fields updated and refresh binding with the new instruction value.
            pubsubService.subscribe(
              'WORKFLOW_FIELDS_UPDATED',
              function (event, fields) {
                const instructionField = fields?.find(
                  (item) => item.Name === $scope.field.schema.name
                );
                if (!instructionField) {
                  return;
                }

                ctrl.instructionText = validationService.sanitizeString(
                  $sce.trustAsHtml(instructionField.Instruction)
                );

                fileService
                  .replaceFileIdsWithImages(instructionField.Instruction)
                  .then((result) => {
                    ctrl.instructionText = validationService.sanitizeString(
                      $sce.trustAsHtml(result)
                    );
                    $timeout(
                      fileService.addFileDownloadClickEventListener,
                      300
                    );
                  });
              },
              subscriberId
            );

            $scope.$on('$destroy', () => {
              pubsubService.unsubscribeAll(subscriberId);
            });
          }

          function setInstructionText() {
            let rawValue = null;

            if ($scope.fieldSchema.isNodeFromRunnerProcessMap) {
              rawValue = $scope.fieldSchema.value;
            } else {
              rawValue = ctrl.form.data[$scope.field.schema.name]
                ? ctrl.form.data[$scope.field.schema.name]
                : ctrl.field.schema.value;
            }

            ctrl.instructionText = validationService.sanitizeString(
              $sce.trustAsHtml(rawValue)
            );

            fileService.replaceFileIdsWithImages(rawValue).then((result) => {
              ctrl.instructionText = validationService.sanitizeString(
                $sce.trustAsHtml(result)
              );
              $timeout(fileService.addFileDownloadClickEventListener, 300);
            });
          }
        }
      ]
    };
  }
]);
