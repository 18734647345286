angular
  .module('fg')
  .directive('fgFieldEmail', [
    'fgFieldEmailLinkFn',
    function (fgFieldEmailLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/email.ng.html',
        scope: true,
        link: fgFieldEmailLinkFn
      };
    }
  ])
  .factory('fgFieldEmailLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
