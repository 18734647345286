angular.module('fg').controller('fgPropertyFieldOptionsController', [
  '$scope',
  function ($scope) {
    this.addButtonText =
      $scope.field.type === 'tasklist' ? 'Add Task' : 'Add Option';

    // Monitor for changes in the options array and ensure a
    // watch for every option value.
    // Watchers are deleted when removing options from the array.

    $scope.$watchCollection('field.options', function (options) {
      if (options) {
        angular.forEach(options, function (option) {
          if (!option.$_valueWatchFn) {
            option.$_valueWatchFn = $scope.$watch(function () {
              return option.value;
            }, handleValueChange);
          }
        });
      }
    });

    function handleValueChange(newValue, oldValue) {
      // Called by the watch collection
      // Ensure that when the selected value is changed, this
      // is synced to the field value.

      if (newValue !== oldValue) {
        if ($scope.multiple) {
          $scope.field.value[newValue] = $scope.field.value[oldValue];
          delete $scope.field.value[oldValue];
        } else {
          if (oldValue === $scope.field.value) {
            $scope.field.value = newValue;
          }
        }
      }
    }

    function newGuid() {
      function chunk(s) {
        var p = (Math.random().toString(16) + '000000000').substr(2, 8);
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
      }
      return chunk() + chunk(true) + chunk(true) + chunk();
    }

    this.addOption = function () {
      if (!$scope.field.options) {
        $scope.field.options = [];
      }

      var index = $scope.field.options.length - 1;
      var optIndex;

      if (index === -1) {
        optIndex = 1;
      } else {
        optIndex = +$scope.field.options[index].value + 1;
      }

      var option = {
        value: optIndex.toString(),
        text: 'Option ' + optIndex,
        id: newGuid()
      };

      if ($scope.field.type === 'tasklist') {
        option.text = 'Task ' + optIndex;
      }

      $scope.field.options.push(option);

      var count = $scope.field.options.length;

      if (!$scope.multiple && count === 1) {
        $scope.field.value = option.value;
      }
    };

    this.removeOption = function (index) {
      var options = $scope.field.options.splice(index, 1);

      if (options && options.length) {
        var option = options[0];

        if ($scope.multiple) {
          if ($scope.field.value[option.value] !== undefined)
            delete $scope.field.value[option.value];
        } else {
          if (
            option.value === $scope.field.value &&
            $scope.field.options.length
          ) {
            $scope.field.value = $scope.field.options[0].value;
          }

          option.$_valueWatchFn();
        }
      }
    };
  }
]);
