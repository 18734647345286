angular
  .module('fg')
  .directive('fgFieldPalette', [
    'fgFieldPaletteLinkFn',
    function (fgFieldPaletteLinkFn) {
      return {
        require: ['^?fgForm', 'fgFieldPalette'],
        replace: true,
        templateUrl: 'angular-form-gen/form/field/fieldPalette.ng.html',
        scope: {
          fieldSchema: '=fgFieldPalette', // The schema definition of the field
          tabIndex: '=?fgTabIndex', // Optional tab index -- used in overlay mode to disable focus
          editMode: '=?fgEditMode', // Indicates edit mode, which will sync the fieldSchema.value
          // to the form data for WYSIWYG pleasures.
          noValidationSummary: '=fgNoValidationSummary' // If true hides the validation summary
        },
        controller: 'fgFieldController',
        link: fgFieldPaletteLinkFn
      };
    }
  ])
  .factory('fgFieldPaletteLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {
      var fgFormCtrl = ctrls[0];
      var fgFieldCtrl = ctrls[1];

      if ($scope.tabIndex === undefined) {
        $scope.tabIndex = 'auto';
      }

      //$scope.renderInfo = fgUtils.getRenderInfo($scope.fieldSchema); //TODO can we remove this?
      $scope.type = $scope.fieldSchema.type.toLowerCase();

      fgFieldCtrl.init(fgFormCtrl, $scope.fieldSchema, $scope.editMode);
    };
  });
