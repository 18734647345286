angular
  .module('fg')
  .directive('fgForm', [
    'fgFormCompileFn',
    function (fgFormCompileFn) {
      return {
        restrict: 'AE',
        require: ['^?form', 'fgForm', '^fgSchema'],
        controller: 'fgFormController',
        scope: true,
        compile: fgFormCompileFn
      };
    }
  ])
  .factory('fgFormLinkFn', [
    'pubsubService',
    function (pubsubService) {
      return function link($scope, $element, $attrs, ctrls) {
        var ngFormCtrl = ctrls[0];
        var formCtrl = ctrls[1];
        var schemaCtrl = ctrls[2];

        var editMode = $attrs.fgNoRender === 'true';

        //get the stepId associatd with this form
        //currently used by the fileupload directive(s)
        var stepId = $attrs.fgForm;

        //we need to reinitalise when the step id changes
        $scope.$watch(
          function () {
            return $attrs.fgForm;
          },
          function (stepId) {
            var _workflowFields =
              typeof $attrs.fgFields === 'string'
                ? JSON.parse($attrs.fgFields)
                : [];
            pubsubService.publish('WORKFLOW_FIELDS_UPDATED', _workflowFields);
            formCtrl.init(
              $attrs.fgFormData,
              schemaCtrl,
              ngFormCtrl,
              editMode,
              stepId,
              flowInstanceId,
              $attrs.fgFormPreviousCustomValidationData,
              _workflowFields,
              $attrs.fgIsWorkflow,
              $attrs.flowModelId,
              $attrs.isPublic
            );
          }
        );

        var flowInstanceId = $attrs.fgFormFlowId;
        var workflowFields =
          typeof $attrs.fgFields === 'string'
            ? JSON.parse($attrs.fgFields)
            : [];
        var isWorkflow = $attrs.fgIsWorkflow === 'true';

        formCtrl.init(
          $attrs.fgFormData,
          schemaCtrl,
          ngFormCtrl,
          editMode,
          stepId,
          flowInstanceId,
          $attrs.fgFormPreviousCustomValidationData,
          workflowFields,
          isWorkflow,
          $attrs.flowModelId,
          $attrs.isPublic
        );
      };
    }
  ])
  .factory('fgFormCompileFn', [
    'fgFormLinkFn',
    function (fgFormLinkFn) {
      return function ($element, $attrs) {
        $element.addClass('fg-form');

        var noRender = $attrs.fgNoRender;

        if (noRender !== 'true') {
          var renderTemplate = '<div fg-form-fields></div>';
          $element.append(renderTemplate);
        }

        return fgFormLinkFn;
      };
    }
  ]);
