import angular from 'angular';
import IFormattedStepSchema from '@Angular.Runner/interfaces/IFormattedStepSchema';

angular.module('fg').controller('fgSchemaController', [
  'fgUtils',
  '$timeout',
  'pubsubService',
  'flowinglyConstants',
  function (fgUtils, $timeout, pubsubService, flowinglyConstants) {
    let _model: IFormattedStepSchema;

    this.model = function (value: IFormattedStepSchema) {
      if (value !== undefined) {
        _model = value;

        if (!angular.isArray(value.fields)) {
          value.fields = [];
        }
      }

      return _model;
    };

    this.addField = function (field, index, needScroll) {
      collapseFields();
      const copy = fgUtils.copyField(field);
      copy.displayProperties = true;

      if (field.type === 'instruction') {
        // default to true, FLOW-2418 Given an instruction text is added to a form, Display Instruction text in completed steps in Runner + Visibility icon will default to on
        copy.displayInCompletedSteps = true;
      }

      if (field.type === 'attachdocument') {
        // FLOW-5012 - default to false
        copy.displayInCompletedSteps = false;
      }

      index = index === undefined ? _model.fields.length : index;
      _model.fields.splice(index, 0, copy);
      if (needScroll) {
        $timeout(function () {
          const newField = angular.element('#' + copy.name);
          const fieldContainer = angular.element('#fieldContainer');

          // don't scroll if only one field
          if (newField && fieldContainer && _model.fields.length > 1) {
            fieldContainer[0].scrollTop =
              fieldContainer[0].scrollHeight - newField.height() - 120;
          }
        });
      }
    };

    this.removeField = function (index) {
      _model.fields.splice(index, 1);
      pubsubService.publish(
        'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
        'fgSchemaController'
      );
    };

    this.swapFields = function (fromIndex, toIndex) {
      const fields = _model.fields;
      const fieldLength = _model.fields.filter(
        (x) => x.type !== flowinglyConstants.formFieldType.RECAPTCHA
      ).length;
      if (
        fromIndex < 0 ||
        fromIndex >= fieldLength ||
        toIndex < 0 ||
        toIndex >= fieldLength ||
        fromIndex === toIndex
      ) {
        return;
      }

      const movingField = fields[fromIndex];
      fields[fromIndex] = angular.copy(fields[toIndex]);
      fields[toIndex] = movingField;
    };

    this.moveField = function (fromIndex, toIndex) {
      const fields = _model.fields;
      if (toIndex > fromIndex) {
        --toIndex;
      }
      if (
        fromIndex < 0 ||
        fromIndex >= fields.length ||
        toIndex < 0 ||
        toIndex >= fields.length ||
        fromIndex === toIndex
      ) {
        return;
      }
      const indexChange = toIndex > fromIndex ? 1 : -1;
      const movingField = fields[fromIndex];
      for (
        let currentIndex = fromIndex;
        currentIndex !== toIndex;
        currentIndex += indexChange
      ) {
        fields[currentIndex] = angular.copy(fields[currentIndex + indexChange]);
      }
      fields[toIndex] = movingField;
    };

    function collapseFields() {
      (_model.fields || []).forEach((field) => {
        field.displayProperties = false;
        if (field.type === 'image' && (!field.value || field.disabled)) {
          field.minimized = true;
        }
      });
    }
  }
]);
