angular
  .module('fg')
  .directive('fgFieldPassword', [
    'fgFieldPasswordLinkFn',
    function (fgFieldPasswordLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/password.ng.html',
        scope: true,
        link: fgFieldPasswordLinkFn
      };
    }
  ])
  .factory('fgFieldPasswordLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
