angular
  .module('fg')
  .directive('fgFieldDate', [
    'fgFieldDateLinkFn',
    function (fgFieldDateLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/date.ng.html',
        scope: true,
        controller: [
          '$scope',
          '$timeout',
          function ($scope, $timeout) {
            var ctrl = this;

            ctrl.init = function (field, form) {
              ctrl.field = field;
              ctrl.form = form;
              ctrl.options = {
                placeholder: 'DD/MM/YYYY',
                format: 'dd/MM/yyyy',
                parseFormats: ['dd/MM/yyyy']
              };

              $timeout(function () {
                getDatePickerElement()
                  .kendoDatePicker(ctrl.options)
                  .attr('readonly', true)
                  .attr('placeholder', ctrl.options.placeholder);
              });
            };

            ctrl.showDatePicker = function () {
              var datePicker = getDatePicker();

              if (datePicker) {
                datePicker.open();
              }
            };

            function getDatePickerElement() {
              return angular.element('#' + ctrl.field.$_id);
            }

            function getDatePicker() {
              return getDatePickerElement().data('kendoDatePicker');
            }
          }
        ],
        controllerAs: 'ctrl',
        link: fgFieldDateLinkFn
      };
    }
  ])
  .factory('fgFieldDateLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
