angular
  .module('fg')
  .directive('fgFieldTaskList', [
    'fgFieldTaskListLinkFn',
    function (fgFieldTaskListLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/tasklist.ng.html',
        scope: true,
        controllerAs: 'ctrl',
        controller: [
          '$scope',
          function ($scope) {
            var ctrl = this;

            ctrl.completedItems = 0;
            ctrl.init = init;
            ctrl.updateProgress = updateProgress;
            ctrl.progressTotal = 0;
            ctrl.options = [];
            ctrl.field = {
              state: {
                $valid: ctrl.isValid
              }
            };

            function init(field, form) {
              ctrl.field = field;

              if (ctrl.field.state === undefined) {
                ctrl.field.state = {};
              }
              ctrl.form = form;

              if (typeof field.schema.options !== 'undefined') {
                for (var i = 0; i < field.schema.options.length; i++) {
                  var opt = field.schema.options[i];

                  field.schema.options[i] = {
                    name: field.schema.name + '[' + i + ']',
                    value: opt.value,
                    text: opt.text,
                    checked: false
                  };
                }
              }

              //if there is any data from the server it will look like this [{"key":"Task 1","value":"true","values":null},{"key":"Task 2","value":"true","values":null}]
              //needs to be converted to this { "1": true, "2": true } and then applied to the model
              var formData = {};
              if (form.data[field.name] && form.data[field.name].length > 0) {
                for (var j = 0; j < form.data[field.name].length; j++) {
                  if (form.data[field.name][j].value == 'true') {
                    var key = form.data[field.name][j].key;

                    var index = field.schema.options.findIndex(function (opt) {
                      return opt.text === key;
                    });

                    if (index >= 0) {
                      field.schema.options[index].checked = true;
                      formData[field.schema.options[index].value] = true;
                    }
                  }
                }
                form.data[field.name] = formData;
                updateProgress();
              }
            }

            function updateProgress() {
              var cnt = 0;
              var chkBox = ctrl.form.data[ctrl.field.schema.name];

              for (var chd in chkBox) {
                if (chkBox[chd]) {
                  cnt++;
                }
              }
              ctrl.completedItems = cnt;
              if (typeof ctrl.field.schema.options !== 'undefined')
                ctrl.progressTotal = Math.round(
                  (ctrl.completedItems / ctrl.field.schema.options.length) * 100
                );
              angular
                .element('#' + ctrl.field.schema.name + '_progress')
                .width(ctrl.progressTotal + '%');

              if (
                ctrl.field.state &&
                typeof ctrl.field.schema.options !== 'undefined'
              ) {
                ctrl.field.state.$invalid = !(
                  ctrl.completedItems === ctrl.field.schema.options.length ||
                  !ctrl.field.schema.validation.required
                );
              }
            }
          }
        ],
        link: fgFieldTaskListLinkFn
      };
    }
  ])
  .factory('fgFieldTaskListLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {
      $scope.$watch(function () {
        return ctrls.form.data[ctrls.field.schema.name];
      }, ctrls.updateProgress);
    };
  });
