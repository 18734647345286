angular.module('fg').component('fgImage', {
  bindings: {
    fileId: '<',
    alignment: '<'
  },
  controller: [
    '$scope',
    'fileService',
    function ($scope, fileService) {
      let $ctrl = this;
      let loadedFileId = null;

      this.$onInit = function () {
        loadImageData();
      };

      this.$onChanges = function () {
        loadImageData();
      };

      function loadImageData() {
        const fileId = $ctrl.fileId;
        if (fileId === undefined || fileId === loadedFileId) {
          return;
        }

        fileService.getFileById(fileId).then((response) => {
          if (response.status !== 200) {
            return;
          }
          loadedFileId = fileId;
          $ctrl.image = {
            data: response.data.data,
            mimeType: response.data.mimeType,
            name: response.data.filename
          };
        });
      }
    }
  ],
  templateUrl: 'angular-form-gen/form/files/image.ng.html'
});
