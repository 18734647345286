import './form/files/file._module';

/**
 * Declare all modules being created by formGen here
 * fgFiles module is declared in file._module so import it instead
 */
(function () {
  'use strict';

  angular.module('dq', []);
  angular.module('fg', ['dq', 'fgFiles', 'angular-storage', 'ngSanitize']);
})();
