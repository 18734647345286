angular
  .module('fg')
  .directive('fgFieldMultiselectList', [
    'fgFieldMultiselectListLinkFn',
    function (fgFieldMultiselectListLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/multiselectlist.ng.html',
        scope: true,
        controllerAs: 'ctrl',
        controller: [
          '$scope',
          function ($scope) {
            var ctrl = this;

            ctrl.init = init;
            ctrl.onChange = onChange;
            ctrl.errorMsg = 'You must select at least 1 item from the list';
            ctrl.form = {
              state: {
                $valid: ctrl.isValid
              }
            };

            function init(field, form) {
              ctrl.field = field;
              ctrl.form = form;

              var orgFormData = ctrl.form.data[ctrl.field.name];

              ctrl.form.data[ctrl.field.name] = {};

              var tempArray = []; //use a temp array as we don't want to update options whilst looping through it

              if (typeof field.schema.options !== 'undefined') {
                for (var i = 0; i < field.schema.options.length; i++) {
                  var opt = field.schema.options[i];

                  tempArray[i] = {
                    name: field.schema.name + '[' + i + ']',
                    value: opt.value,
                    text: opt.text
                  };
                  //plus one as when the user checks something, it starts at 1
                  ctrl.form.data[ctrl.field.name][i + 1] = false;
                }
              }
              field.schema.options = tempArray;

              //if there is any data from the server it will look like this [{"key":"Option 2","value":"true","values":null}]
              //needs to be converted to this { "1": true, "2": true } and then applied to the model
              if (orgFormData !== undefined) {
                form.data[field.name] = orgFormData;
                onChange();
              }
            }

            function onChange() {
              var cnt = 0;
              var chkBox = ctrl.form.data[ctrl.field.schema.name];

              for (var chd in chkBox) {
                if (chkBox[chd]) {
                  cnt++;
                }
              }

              var requireCustomValidation =
                ctrl.field.schema.customValidation &&
                ctrl.field.schema.customValidation.required;
              var requireValidation;

              if (typeof ctrl.field.schema.validation !== 'undefined')
                requireValidation =
                  ctrl.field.schema.validation.required ||
                  requireCustomValidation;
              else requireValidation = false;

              if (requireValidation) {
                if (requireCustomValidation) {
                  ctrl.errorMsg =
                    'You must select at least ' +
                    ctrl.field.schema.customValidation.value +
                    ' item(s) from the list';

                  if (
                    ctrl.field.schema.customValidation.errorMessage &&
                    ctrl.field.schema.customValidation.errorMessage.length > 0
                  ) {
                    ctrl.errorMsg =
                      ctrl.field.schema.customValidation.errorMessage;
                  }
                }

                if (!ctrl.field.state) {
                  ctrl.field.state = {};
                }

                if (requireCustomValidation && cnt > 0) {
                  ctrl.field.state.$invalid =
                    cnt < ctrl.field.schema.customValidation.value;
                } else {
                  ctrl.field.state.$invalid =
                    ctrl.field.schema.validation.required && cnt === 0;
                }
              }
            }
          }
        ],
        link: fgFieldMultiselectListLinkFn
      };
    }
  ])
  .factory('fgFieldMultiselectListLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
