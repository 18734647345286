angular
  .module('fg')
  .directive('fgFieldApprovalComment', [
    'fgFieldApprovalCommentLinkFn',
    function (fgFieldApprovalCommentLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/approvalcomment.ng.html',
        scope: true,
        link: fgFieldApprovalCommentLinkFn
      };
    }
  ])
  .factory('fgFieldApprovalCommentLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
