angular.module('fg').controller('tablePropertyController', [
  '$scope',
  function ($scope) {
    var ctrl = this;
    ctrl.onTableUpdated = onTableUpdated;
    function onTableUpdated(value) {
      //here we need to save the table schema such that it can be rendered in the runner
      //set the fields schema property to the table schema
      ctrl.field.tableSchema = value;

      //the runner will then save the populated table to the field.value property
    }
  }
]);
