angular
  .module('fg')
  .directive('fgFieldNotFound', [
    'fgFieldNotFoundLinkFn',
    function (fgFieldNotFoundLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/not-in-cache.ng.html',
        scope: true,
        link: fgFieldNotFoundLinkFn
      };
    }
  ])
  .factory('fgFieldNotFoundLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
