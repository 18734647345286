angular.module('fg').directive('fgFileDrag', [fgFileDrag]);

function fgFileDrag() {
  var directive = {
    restrict: 'A',
    require: ['?fgFileUpload', '?attachDocumentFileUpload', '?imageFileUpload'],

    link: function (scope, element, attrs, ctrls) {
      element
        .on('drag dragstart dragend dragover dragenter dragleave drop', (e) => {
          e.preventDefault();
          e.stopPropagation();
        })
        .on('dragover dragenter', () => {
          element.addClass('dragging');
        })
        .on('dragleave dragend drop', () => {
          element.removeClass('dragging');
        })
        .on('drop', (e) => {
          e.target = { files: e.originalEvent.dataTransfer.files };
          ctrls.forEach((control) => {
            if (control && control.onFileChange) {
              control.onFileChange(e);
            }
          });
        });
    }
  };

  return directive;
}
