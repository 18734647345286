angular
  .module('fg')
  .directive('fgFieldTable', [
    'fgFieldTableLinkFn',
    function (fgFieldTableLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/table.ng.html',
        scope: true,
        link: fgFieldTableLinkFn
      };
    }
  ])
  .factory('fgFieldTableLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
