angular.module('fg').directive('uploadFileOnchange', [uploadFileOnchange]);

function uploadFileOnchange() {
  var directive = {
    restrict: 'A',
    require: ['?attachDocumentFileUpload', '?fgFileUpload', '?imageFileUpload'],
    link: function (scope, element, attrs, ctrls) {
      ctrls.forEach((ctrl) => {
        if (!ctrl || !ctrl.onFileChange) {
          return;
        }

        const onChangeHandler = ctrl.onFileChange;
        element.bind('change', onChangeHandler);

        element.bind('click', function () {
          //clear the value so that we can select a file with the same name
          element[0].value = '';
        });

        scope.$on('$destroy', function () {
          element.unbind('change', onChangeHandler);
        });
      });
    }
  };

  return directive;
}
