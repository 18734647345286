angular
  .module('fg')
  .directive('fgFieldDatetime', [
    'fgFieldDatetimeLinkFn',
    function (fgFieldDatetimeLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/datetime.ng.html',
        scope: true,
        controller: [
          '$scope',
          '$timeout',
          function ($scope, $timeout) {
            var ctrl = this;

            ctrl.init = function (field, form) {
              ctrl.field = field;
              ctrl.form = form;
              ctrl.options = {
                placeholder: 'DD/MM/YYYY HH:MM:SS AM/PM',
                format: 'dd/MM/yyyy hh:mm:ss tt',
                parseFormats: ['dd/MM/yyyy h:mm:ss tt'],
                timeFormat: 'h:mm tt',
                interval: 5
              };

              $timeout(function () {
                getDatetimePickerElement()
                  .kendoDateTimePicker(ctrl.options)
                  .attr('readonly', true)
                  .attr('placeholder', ctrl.options.placeholder);
              });
            };

            ctrl.showDatetimePicker = function () {
              var datetimePicker = getDatetimePicker();

              if (datetimePicker) {
                datetimePicker.open();
              }
            };

            function getDatetimePickerElement() {
              return angular.element('#' + ctrl.field.$_id);
            }

            function getDatetimePicker() {
              return getDatetimePickerElement().data('kendoDateTimePicker');
            }
          }
        ],
        controllerAs: 'ctrl',
        link: fgFieldDatetimeLinkFn
      };
    }
  ])
  .factory('fgFieldDatetimeLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
