angular
  .module('fg')
  .directive('fgFieldAttachDocument', [
    'fgFieldAtttachDocumentFn',
    function (fgFieldAtttachDocumentFn) {
      return {
        replace: true,
        controllerAs: 'ctrl',
        controller: [
          '$http',
          '$q',
          'sessionService',
          'fgAttachDocumentFileListService',
          '$scope',
          function (
            $http,
            $q,
            sessionService,
            fgAttachDocumentFileListService,
            $scope
          ) {
            var ctrl = this;
            ctrl.fgAttachDocumentFileListService =
              fgAttachDocumentFileListService;
            ctrl.addFile = addFile;
            ctrl.getFile = getFile;

            function addFile(file) {
              ctrl.nodeId = ctrl.form.schema.fields[0].nodeId;

              fgAttachDocumentFileListService.addFile(
                file,
                ctrl.fieldId,
                ctrl.nodeId
              );
            }

            function getFile(filepath) {
              if (!filepath) {
                return $q.when();
              }

              //Request file data from server, attaching auth header and token (from Auth0)
              var requestConfig = {
                method: 'Get',
                headers: {
                  Authorization: 'Bearer ' + sessionService.getToken()
                },
                url: filepath,
                cache: 'true'
              };

              return $http(requestConfig);
            }
          }
        ],
        templateUrl:
          'angular-form-gen/field-templates/default/attachdocument.ng.html',
        link: fgFieldAtttachDocumentFn
      };
    }
  ])
  .factory('fgFieldAtttachDocumentFn', [
    'pubsubService',
    'APP_CONFIG',
    'sessionService',
    'momentService',
    function (pubsubService, APP_CONFIG, sessionService, momentService) {
      return function ($scope, $element, $attrs, ctrls) {
        ctrls.fieldId = $attrs.fileControlId;
        ctrls.form = $scope.$parent.form;
        ctrls.user = sessionService.getUser();

        initialiseFile();

        $scope.$watch(function () {
          return ctrls.form.data && ctrls.form.data[ctrls.fieldId];
        }, initialiseFile);

        //if there is a file already uploaded against this field, add it to the list
        function initialiseFile() {
          if (ctrls.user == undefined) {
            return; // Public forms are handled by attach.document.file.list.readonly
          }
          ctrls.fgAttachDocumentFileListService.clearFiles(ctrls.fieldId);
          if (ctrls.form.schema && ctrls.form.schema.fields.length > 0) {
            var field = [];

            for (var _f = 0; _f < ctrls.form.schema.fields.length; _f++) {
              if (ctrls.form.schema.fields[_f].name == ctrls.fieldId)
                field = ctrls.form.schema.fields[_f];
            }

            var files = field.attachDocumentFileIds
              ? field.attachDocumentFileIds
              : [];

            for (var f = 0; f < files.length; f++) {
              var filepath = generateLink(files[f]);

              getFile(filepath, generateDownloadLink(files[f]));
            }
          }
        }

        function getFile(filepath, downloadLink) {
          //get the file from the server (so we an get filename and size)
          ctrls.getFile(filepath).then(function (response) {
            if (!response) {
              return;
            }

            var fileData = response.data;
            var file = {
              filename: fileData.filename,
              id: downloadLink.match(/(\S{8}-\S{4}-\S{4}-\S{4}-\S{12}$)/)[0],
              key: ctrls.fieldId,
              downloadLink: downloadLink,
              size: fileData.size,
              uploadedDate: momentService
                .utc(fileData.uploadedDate)
                .format('DD/MM/YYYY')
            };
            //for it to show up in the control it needs to be added to the file list
            ctrls.addFile(file);
            pubsubService.publish('FILEUPLOAD_UPLOAD_COMPLETED');
          });
        }

        //same functions in shared.angular for table viewer but of course, we're not using tables here
        function generateLink(fileId) {
          return fileId !== undefined
            ? `${APP_CONFIG.apiBaseUrl}files/${ctrls.user.businessId}/${ctrls.user.id}/${fileId}`
            : undefined;
        }
        function generateDownloadLink(fileId) {
          return fileId !== undefined
            ? `${APP_CONFIG.apiBaseUrl}files/download/${fileId}`
            : undefined;
        }
      };
    }
  ]);
