angular
  .module('fg')
  .directive('fgFieldNumber', [
    'fgFieldNumberLinkFn',
    function (fgFieldNumberLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/number.ng.html',
        scope: true,
        controllerAs: 'ctrl',
        controller: [
          '$scope',
          function ($scope) {
            const ctrl = this;
            ctrl.init = init;

            function init() {
              disableMouseWheelScrollInNumberField();
            }

            function disableMouseWheelScrollInNumberField() {
              $('form').on('focus', 'input[type=number]', function (e) {
                $(this).on('wheel.disableScroll', function (e) {
                  // Stop the user from using the mouse wheel to change the value of the number field.
                  e.preventDefault();
                });
              });

              $('form').on('blur', 'input[type=number]', function (e) {
                // Avoid listening for the mouse wheel event on all input number elements
                // all the time. Otherwise the user cannot scroll the page.
                $(this).off('wheel.disableScroll');
              });
            }
          }
        ],
        link: fgFieldNumberLinkFn
      };
    }
  ])
  .factory('fgFieldNumberLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {
      $scope.options = {
        placeholder: $scope.field.schema.placeholder
      };
    };
  });
