angular.module('fg').directive('fgDisableSubmit', [
  function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
          var returnKey = 13;
          var keyCode = event.keyCode ? event.keyCode : event.which;
          if (keyCode === returnKey) {
            event.preventDefault();
          }
        });
      }
    };
  }
]);
