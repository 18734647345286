angular
  .module('fg')
  .directive('fgFieldTextArea', [
    'fgFieldTextAreaLinkFn',
    function (fgFieldTextAreaLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/textarea.ng.html',
        scope: true,
        link: fgFieldTextAreaLinkFn
      };
    }
  ])
  .factory('fgFieldTextAreaLinkFn', [
    '$timeout',
    'validationService',
    'APP_CONFIG',
    function ($timeout, validationService, appConfig) {
      return function ($scope, $element, $attrs, ctrls) {
        var enablePasteCleanUpOption = {
          css: true,
          msAllFormatting: true,
          msConvertLists: true,
          msTags: true,
          span: true,
          keepNewLines: true
        };

        var disablePasteCleanUpOption = { all: false };

        $scope.pasteCleanUpOption = appConfig.disableXssValidation
          ? disablePasteCleanUpOption
          : enablePasteCleanUpOption;

        var validatorOptions = {
          messages: {
            url: 'Invalid URL',
            xss: 'This input does not support HTML',
            req: 'Required'
          },
          rules: {
            url: function (input) {
              var regForUrl = new RegExp(
                /^(h|H)(T|t)(t|T)(P|p)(S|s?):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
              );
              if (input.is('#k-editor-link-url')) {
                if (!regForUrl.test(input.val())) {
                  input.attr('name', 'k-editor-link-url');
                  return false;
                }
              }
              return true;
            },
            xss: function (input) {
              if (
                input.is('#k-editor-link-text') ||
                input.is('#k-editor-link-title')
              ) {
                if (validationService.isXssVulnerableString(input.val())) {
                  input.attr('name', input.attr('id'));
                  return false;
                }
              }
              return true;
            },
            req: function (input) {
              if (input.is('#k-editor-link-text')) {
                if (input.val().length < 1) {
                  input.attr('name', 'k-editor-link-text');
                  return false;
                }
              }
              return true;
            }
          }
        };

        function editorExecute(e) {
          setTimeout(function () {
            if (e.name === 'createlink') {
              const form = e.command._dialog.element;
              const selectors = ['#k-editor-link-target'];
              restyleCheckBoxes(form, selectors);
              configureCreateLink(e.command);
            } else if (e.name.startsWith('tablewizard')) {
              const form = $('.k-widget.k-window.k-state-focused');
              const selectors = [
                '#k-editor-selectAllCells',
                '#k-editor-cells-headers',
                '#k-editor-wrap-text',
                '#k-editor-collapse-borders'
              ];
              restyleCheckBoxes(form, selectors);
              configureTableWizard(e.command);
            }
          }, 0);
        }

        function restyleCheckBoxes(form, targetClasses) {
          // FLOW-6400 -To get checkbox in Insert Hyperlink kendo pop-up to style similar to others in Flowingly,
          // removing the kendo checkbox default class so that global Flowingly css checkbox style will take effect.
          targetClasses.forEach((selector) => {
            const linkTarget = form.find(selector);
            if (linkTarget) {
              linkTarget.removeClass('k-checkbox');
            }
          });
        }

        function configureTableWizard(command) {
          // Otherwise some of the options aren't visible
          $('#k-table-properties').css('overflow-y', 'scroll');
          $('#k-cell-properties').css('overflow-y', 'scroll');
          $('.k-editor-table-wizard-dialog').css('width', '27em');

          // Don't want users setting class and index
          $('#k-editor-css-class').hide();
          $('label[for="k-editor-css-class"]').hide();

          $('#k-editor-id').hide();
          $('label[for="k-editor-id"]').hide();

          $('#k-editor-cell-css-class').hide();
          $('label[for="k-editor-cell-css-class"]').hide();

          $('#k-editor-cell-id').hide();
          $('label[for="k-editor-cell-id"]').hide();
        }

        function configureCreateLink(command) {
          var form = command._dialog.element;
          var insertButton = form.find('.k-dialog-insert');
          form.find(':input[type="text"]').each(function (index, item) {
            $(item).off();
            $(item).on('keydown', function (event) {
              if (event.keyCode === 13) {
                insertButton.trigger('click');
              }
            });
          });

          //remove original events
          insertButton.off();

          //initialize Kendo UI validator
          form.kendoValidator(validatorOptions);

          insertButton.on(
            'click',
            $.proxy(function (e) {
              if (form.data('kendoValidator').validate()) {
                //if validation pass close the editor command:
                this._apply(e);
              } else {
                e.preventDefault();
              }
            }, command)
          );
        }

        $timeout(function () {
          // Find all the text editor and bind the execute function.
          let editorElements = $('[data-text-editor="textarea"]');

          try {
            editorElements.toArray().forEach((element) => {
              let editor = $(element).data('kendoEditor');
              if (editor) {
                editor.bind('execute', editorExecute);
              }
            });
          } catch (e) {
            // Swallow error and prevent code termination.
          }
        }, 500);
      };
    }
  ]);
