angular
  .module('fg')
  .directive('fgAttachDocumentFileListReadonly', [
    fgAttachDocumentFileListReadonly
  ]);

function fgAttachDocumentFileListReadonly() {
  var directive = {
    require: ['?^fgForm', 'fgAttachDocumentFileListReadonly'],
    scope: {
      fieldId: '<',
      nodeId: '<',
      field: '<',
      form: '='
    },
    bindToController: true,
    controller: [
      'fgAttachDocumentFileListService',
      'sessionService',
      'flowinglyConstants',
      'fileService',
      function controller(
        fgAttachDocumentFileListService,
        sessionService,
        flowinglyConstants,
        fileService
      ) {
        var ctrl = this;
        ctrl.user = sessionService.getUser();
        ctrl.init = init;
        ctrl.getAttachDocumentsforModeler = getAttachDocumentsforModeler;
        ctrl.getAttachDocumentsforRunnerWorkFlows =
          getAttachDocumentsforRunnerWorkFlows;

        function init(fgFormCtrl) {
          /*init triggers when:
                        1. Runner > Workflows > A step with this field
                        2. Modeler > Clicking a node with this field
                        3. Modeler > Clicking Design at the right-hand panel for a node with this field
                        4. Modeler > In Design, adding a new Attach Document field */

          if (fgFormCtrl != null) {
            fgFormCtrl.stepId =
              typeof fgFormCtrl.stepId !== 'undefined'
                ? fgFormCtrl.stepId
                : null;
            fgFormCtrl.nodeId = ctrl.nodeId != null ? ctrl.nodeId : null;

            ctrl.fgFormCtrl = fgFormCtrl;
            ctrl.isPublicForm = fgFormCtrl.isPublic;

            if (fgFormCtrl.isWorkflow) {
              ctrl.form._fields = fgFormCtrl.flowFields;
            } else {
              fgAttachDocumentFileListService.storeForm(fgFormCtrl);
              fgAttachDocumentFileListService.registerFileControl(
                this.fieldId,
                fgFormCtrl.stepId,
                ctrl.nodeId
              );
            }
          }
        }

        function getAttachDocumentsforModeler() {
          return fgAttachDocumentFileListService.getFilesForControl(
            ctrl.fieldId,
            ctrl.nodeId
          );
        }

        function getAttachDocumentsforRunnerWorkFlows() {
          let adList = [];
          let field = null;
          if (!ctrl.form._fields || ctrl.form._fields.length === 0) {
            return adList;
          }
          // Internal form fields have PascalCase
          // Public form fields have camelCase ...
          if (ctrl.form._fields[0].Type !== undefined) {
            field = ctrl.form._fields.find((f) => {
              return (
                f.Type === flowinglyConstants.formFieldType.ATTACH_DOCUMENT &&
                f.Name == ctrl.fieldId
              );
            });
            if (field) {
              field.AttachDocumentElements.forEach((element) => {
                element.DownloadLink = fileService.getDownloadLink(
                  element.FileId,
                  ctrl.isPublicForm
                );
              });

              adList = field.AttachDocumentElements
                ? field.AttachDocumentElements
                : [];
            }
          } else if (ctrl.form._fields[0].type !== undefined) {
            field = ctrl.form._fields.find((f) => {
              return (
                f.type ===
                  flowinglyConstants.formFieldTypeEnum.ATTACH_DOCUMENT &&
                f.name == ctrl.fieldId
              );
            });
            if (field) {
              field.attachDocumentElements.forEach((element) => {
                element.DownloadLink = fileService.getDownloadLink(
                  element.fileId,
                  ctrl.isPublicForm
                );
              });

              adList = field.attachDocumentElements
                ? field.attachDocumentElements
                : [];
            }
          }
          return adList;
        }
      }
    ],
    controllerAs: 'ctrl',
    link: function (scope, element, attrs, ctrls) {
      var fgFormCtrl = ctrls[0];
      var fgFileListCtrl = ctrls[1];

      fgFileListCtrl.fieldId = fgFileListCtrl.fieldId
        ? fgFileListCtrl.fieldId
        : scope.ctrl.field.schema.name;
      fgFileListCtrl.init(fgFormCtrl);
    },

    template: [
      '<div>' +
        //RUNNER/WORKFLOWS
        '<ul ng-if="ctrl.form.isWorkFlow" ng-init="workflow_ADfiles = ctrl.getAttachDocumentsforRunnerWorkFlows()">' +
        '   <li ng-repeat="file in workflow_ADfiles | orderBy : \'filename\'">' +
        '       <a flow-download-link="{{::file.DownloadLink}}" target="_blank" > {{::(file.FileName || file.fileName)}}</a>' +
        '   </li>' +
        '</ul>' +
        //MODELER/RIGHT SIDE PANEL + MODELER/DESIGN DIALOG
        '<ul ng-if="!ctrl.field.schem.isNodeFromRunnerProcessMap && !ctrl.form.isWorkFlow" ng-init="modeler_ADfiles = ctrl.getAttachDocumentsforModeler()">' +
        '   <li ng-repeat="file in modeler_ADfiles | orderBy : \'filename\'">' +
        '       <b>{{::file.filename}}</b>' +
        '   </li>' +
        '</ul>' +
        '</div>'
    ]
  };
  return directive;
}
