import angular from 'angular';
import IFormattedStepSchema from '@Angular.Runner/interfaces/IFormattedStepSchema';

angular
  .module('fg')
  .directive('fgSchema', [
    'fgSchemaLinkFn',
    function (fgSchemaLinkFn) {
      return {
        require: ['fgSchema'],
        controller: 'fgSchemaController',
        link: fgSchemaLinkFn
      };
    }
  ])
  .factory('fgSchemaLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {
      const schemaCtrl = ctrls[0];

      $scope.$watch($attrs.fgSchema, function (value: IFormattedStepSchema) {
        schemaCtrl.model(value);
      });
    };
  });
