angular
  .module('fg')
  .directive('fgFieldApprovalRule', [
    'fgFieldApprovalRuleLinkFn',
    function (fgFieldApprovalRuleLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/approvalrule.ng.html',
        scope: true,
        link: fgFieldApprovalRuleLinkFn
      };
    }
  ])
  .factory('fgFieldApprovalRuleLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {
      const approveOptionValue = '1';
      var commentRequired = function () {
        return (
          $scope.form.data[$scope.field.schema.name] !== null &&
          $scope.form.data[$scope.field.schema.name] !== approveOptionValue
        );
      };

      $scope.isCommentRequired = commentRequired();

      $scope.selectOption = function (option) {
        if (!$scope.field.schema.$recentValue) {
          $scope.field.schema.$recentValue = option.value;
          $scope.isCommentRequired =
            option.value === approveOptionValue ? false : true;
        } else {
          if (option.value != $scope.field.schema.$recentValue)
            $scope.field.schema.$recentValue = option.value;
          else {
            $scope.field.schema.$recentValue = null;
            $scope.form.data[$scope.field.schema.name] = null;
          }
        }
      };

      $scope.$watch(
        function () {
          return $scope.form.data[$scope.field.schema.name];
        },
        function () {
          $scope.isCommentRequired = commentRequired();
        }
      );
    };
  });
