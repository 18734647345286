angular.module('fg').directive('fgFieldSignature', [
  '$timeout',
  function ($timeout) {
    //NOTE requires signature-pad depedency to be loaded by the site using this directive
    //     https://github.com/szimek/signature_pad

    return {
      replace: true,
      controllerAs: 'ctrl',
      controller: [
        '$scope',
        function ($scope) {
          var ctrl = this;
          ctrl.penColor = 'rgb(0, 0, 0)';
          ctrl.backgroundColor = 'rgb(255, 255, 255)';
        }
      ],
      templateUrl: 'angular-form-gen/field-templates/default/signature.ng.html',
      link: function (scope, iElem, $attrs, ctrls) {
        //create signature pad
        var canvas = angular.element(
          iElem[0].querySelector('#signature-pad')
        )[0];
        var signaturePad = new SignaturePad(canvas, {
          backgroundColor: ctrls.backgroundColor,
          penColor: ctrls.penColor,
          onEnd: saveSignature
        });

        scope.$watch(function () {
          return scope.form.data[scope.field.schema.name];
        }, updateSignatureData);

        $timeout(function () {
          //for devices that are smaller than the standard signature size, use the existing canvas size
          canvas.height = canvas.clientHeight > 125 ? 125 : canvas.clientHeight;
          canvas.width = canvas.clientWidth > 500 ? 500 : canvas.clientWidth;

          //for some reason the canvas has no height or width on Modeler
          //update against that
          canvas.height = canvas.height === 0 ? 125 : canvas.height;
          canvas.width = canvas.width === 0 ? 500 : canvas.width;

          updateSignatureData();

          //handle X button
          var button = angular.element(iElem[0].querySelector('#closeButton'));
          button.on('click', function () {
            signaturePad.clear();
            scope.form.data[scope.field.schema.name] = undefined;
          });
        }, 500);

        function changeSignatureBgColor(sigPad, bgColor) {
          // first get the signature drawn by user
          var currentSignatureData = sigPad.toData();
          //get temp canvas (this is not shown to user, used to generate data to save only)
          var tempCanvas = angular.element(
            iElem[0].querySelector('#signature-pad-temp')
          )[0];
          tempCanvas.height =
            canvas.clientHeight > 125 ? 125 : canvas.clientHeight;
          tempCanvas.width =
            canvas.clientWidth > 500 ? 500 : canvas.clientWidth;
          //create a new sig with specified background, using tempCanvas
          var sigToSave = new SignaturePad(tempCanvas, {
            backgroundColor: bgColor,
            penColor: ctrls.penColor
          });
          //add user drawn content to signaturePad
          sigToSave.fromData(currentSignatureData);

          //return as a url so we can save it
          return sigToSave.toDataURL();
        }

        function saveSignature(value) {
          // we want signature to have a grey background in runner history
          scope.form.data[scope.field.schema.name] = changeSignatureBgColor(
            signaturePad,
            'rgb(249,249,249)'
          );
        }

        function updateSignatureData() {
          if (scope.form.data[scope.field.schema.name] != undefined) {
            //here we need to restore the previously saved (on blur) signature
            //saved signature has grey background, so we need to convert back to white before display in sig pad

            //first we need to load the saved sig
            signaturePad.fromDataURL(scope.form.data[scope.field.schema.name]);

            //then change it's background back to white
            // var withWhiteBackground = changeSignatureBgColor(signaturePad, 'rgb(255,255,255)');
            // the method above does not work because (from docs)
            // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
            // replacing toData and fromData with toDataURL and fromDataURL in changeSignatureBgColor also does not work
          }
        }
      }
    };
  }
]);
