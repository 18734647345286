angular
  .module('fg')
  .directive('fgFieldFormula', [
    'fgFieldFormulaLinkFn',
    function (fgFieldFormulaLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/formula.ng.html',
        scope: true,
        link: fgFieldFormulaLinkFn
      };
    }
  ])
  .factory('fgFieldFormulaLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
