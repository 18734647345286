angular
  .module('fg')
  .directive('fgFieldText', [
    'fgFieldTextLinkFn',
    function (fgFieldTextLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/text.ng.html',
        scope: true,
        link: fgFieldTextLinkFn
      };
    }
  ])
  .factory('fgFieldTextLinkFn', [
    'lodashService',
    '$timeout',
    function (lodashService, $timeout) {
      return function ($scope, $element, $attrs, ctrls) {
        $timeout(function () {
          // we do this because this field is set to either 0 or null when the user
          // wants it infinite, but ng-maxlength only accepts NULL as infinite.
          $scope.maxLength =
            lodashService.get(
              $scope.field,
              'schema.customValidation.maxLength'
            ) || null;

          $scope.form.data[$scope.field.schema.name] = lodashService.unescape(
            $scope.form.data[$scope.field.schema.name]
          );
          /**
           * This isn't a very efficient way of getting the html length but this fixes 2 problems.
           *
           * Problem #1
           * The element is instantiated later at an unknown time by kendo, so without polling, this would be
           * the easier way to do it.
           *
           * Problem #2
           * When using ng-maxlength the ngmodel becomes null if you go beyond the maxlength so we cant use
           * ng-model's length , so instead we have to fetch the raw value ourselves.
           */
          $scope.getRemainingCount = function () {
            return (
              $scope.field.schema.customValidation.maxLength -
              $scope.getInputLength()
            );
          };

          $scope.validateCharCount = function () {
            var isValid = $scope.getRemainingCount() >= 0;
            $scope.form.state.$setValidity('maxlength', isValid);
          };

          $scope.getInputLength = function () {
            var $e = $('#' + $scope.field.$_id);
            if ($e.length) {
              return $e.val().length;
            } else {
              return NaN;
            }
          };

          /**
           * Why not rely on the .has-error class? Because that class appears when the input field
           * is still $pristine for some odd reason.
           */
          $scope.getColor = function () {
            if ($scope.form.state) {
              return $scope.form.state[$scope.field.schema.name].$error
                .maxlength
                ? 'red'
                : 'black';
            } else {
              return 'black';
            }
          };
        });
      };
    }
  ]);
