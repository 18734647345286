angular
  .module('fg')
  .directive('fgFieldDropdown', function (fgFieldDropdownLinkFn) {
    return {
      replace: true,
      templateUrl:
        'angular-form-gen/field-templates/default/dropdownlist.ng.html',
      scope: true,
      link: fgFieldDropdownLinkFn
    };
  })
  .factory('fgFieldDropdownLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
