angular
  .module('fg')
  .directive('fgFieldSubmit', [
    'fgFieldSubmitLinkFn',
    function (fgFieldSubmitLinkFn) {
      return {
        replace: true,
        templateUrl: 'angular-form-gen/field-templates/default/submit.ng.html',
        scope: true,
        link: fgFieldSubmitLinkFn
      };
    }
  ])
  .factory('fgFieldSubmitLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
