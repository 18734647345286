/**
 * @ngdoc directive
 * @name fgImageSelector
 * @description Displays an image field selector for choosing between available business images.
 * @usage
 * ```
 *   <fg-image-selector field="field"></fg-image-selector>
 * ```
 */
angular.module('fg').component('fgImageSelector', {
  bindings: {
    field: '<'
  },
  controller: [
    '$scope',
    'fileService',
    'pubsubService',
    function ($scope, fileService, pubsubService) {
      let $ctrl = this;
      const SUBSCRIBER_NAME = 'ImageSelector_' + Date.now();

      this.$onInit = function () {
        if ($ctrl.field.alignment === undefined) {
          $ctrl.field.alignment = 'center';
        }
        loadImageIds(true);
        pubsubService.subscribe(
          'IMAGEUPLOAD_UPLOAD_COMPLETED',
          (eventName, data) => loadImageIds(data.fieldId !== $ctrl.field.id),
          SUBSCRIBER_NAME
        );
      };

      this.$onDestroy = function () {
        pubsubService.unsubscribeAll(SUBSCRIBER_NAME);
      };

      this.changeSelection = function (change) {
        const images = $ctrl.images;
        let newIndex = $ctrl.currentImageIndex + change;
        if (newIndex < 0) {
          newIndex = images.length - 1;
        } else if (newIndex >= images.length) {
          newIndex = 0;
        }
        $ctrl.currentImageIndex = newIndex;
        loadCurrentImageData();
      };

      function loadImageIds(keepSelection) {
        fileService.getBusinessImageIds().then((ids) => {
          $ctrl.errorMessage = null;
          $ctrl.images = null;

          if (ids === null) {
            $ctrl.errorMessage =
              'An error occurred while trying to load existing files, try re-opening the form designer.';
            return;
          }

          $ctrl.images = ids.map((id, index) => {
            return { id: id };
          });

          const currentSelectionIndex = ids.indexOf($ctrl.field.value);
          $ctrl.currentImageIndex =
            keepSelection && currentSelectionIndex > -1
              ? currentSelectionIndex
              : 0;
          loadCurrentImageData();
        });
      }

      function loadCurrentImageData() {
        const image = $ctrl.images[$ctrl.currentImageIndex];
        if (image === undefined) {
          return;
        }
        if (image.data !== undefined) {
          $ctrl.selectedImage = image;
          $ctrl.field.value = image.id;
          return;
        }

        fileService.getFileById(image.id).then((response) => {
          if (response.status === 200) {
            image.data = response.data.data;
            image.mimeType = response.data.mimeType;
            image.name = response.data.filename;
          }
          $ctrl.selectedImage = image;
          $ctrl.field.value = image.id;
        });
      }
    }
  ],
  templateUrl: 'angular-form-gen/form/files/image.selector.ng.html'
});
