angular
  .module('fg')
  .directive('fgFieldCheckbox', [
    'fgFieldCheckboxLinkFn',
    function (fgFieldCheckboxLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/checkbox.ng.html',
        scope: true,
        link: fgFieldCheckboxLinkFn
      };
    }
  ])
  .factory('fgFieldCheckboxLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
