angular.module('fg').directive('imageFileUpload', function () {
  return {
    restrict: 'A',
    require: ['?^fgForm', 'imageFileUpload'],
    controllerAs: 'ctrl',
    scope: {
      field: '<',
      imageLimit: '<?',
      imageKey: '<?',
      onFileUploaded: '<?'
    },
    controller: [
      '$scope',
      '$element',
      'pubsubService',
      'notificationService',
      'fileService',
      'guidService',
      'sessionService',
      function controller(
        $scope,
        $element,
        pubsubService,
        notificationService,
        fileService,
        guidService,
        sessionService
      ) {
        const BATCH_SIZE = 5;

        let ctrl = this;
        ctrl.fieldId = $scope.field && $scope.field.id;
        ctrl.onFileChange = onFileChange;
        ctrl.uploadFiles = uploadFiles;
        const maxImageDimensionInPixels = 260;

        this.$onInit = function () {
          const user = sessionService.getUser();
          fileService.setUser(user.id, user.businessId);

          const types = ['jpeg', 'jpg', 'jfif', 'bmp', 'png', 'gif'];
          ctrl.allowedFileTypes = types;
          ctrl.allowedFileTypesString = types
            .map((type) => '.' + type)
            .reduce((previous, current) => previous + ', ' + current);
          $element.attr('accept', ctrl.allowedFileTypesString);
        };

        function onFileChange(event) {
          if (event.target.files.length === 0) {
            return;
          }

          pubsubService.publish('IMAGEUPLOAD_UPLOAD_STARTED');
          event.stopPropagation();

          const files = Array.from(event.target.files); // FileList is not an Array
          let filesToUpload = files.filter((file) => {
            const extension = file.name
              .substring(file.name.lastIndexOf('.') + 1)
              .toLowerCase();
            if (ctrl.allowedFileTypes.some((type) => type === extension)) {
              return true;
            }

            notificationService.showWarningToast(
              `${file.name} is not an allowed image type (${ctrl.allowedFileTypesString})`
            );
            return false;
          });

          if ($scope.imageLimit) {
            filesToUpload = filesToUpload.slice(0, $scope.imageLimit);
          }

          uploadFiles(filesToUpload);
        }

        function uploadFiles(filesToUpload) {
          uploadFileBatch(
            Array.prototype.slice.call(filesToUpload),
            0,
            BATCH_SIZE
          );
        }

        /// PRIVATE METHODS //
        function uploadFileBatch(files, start, batchSize) {
          if (!files || batchSize <= 0 || start >= files.length) {
            return;
          }

          var batch = files.slice(start, start + batchSize);
          var promises = batch.map(function (file) {
            fileService
              .reduceImageFile(
                file,
                maxImageDimensionInPixels,
                maxImageDimensionInPixels
              )
              .then(
                (newFile) => {
                  return fileService
                    .uploadFile(
                      guidService.empty(),
                      newFile,
                      $scope.imageKey || 'Logo'
                    )
                    .then(
                      function (response) {
                        if (response.valid) {
                          const eventParams = {
                            fieldId: ctrl.fieldId,
                            fileId: response.data
                          };

                          notificationService.showSuccessToast(
                            file.name + ' uploaded.'
                          );
                          pubsubService.publish(
                            'IMAGEUPLOAD_UPLOAD_COMPLETED',
                            eventParams
                          );
                          $scope.onFileUploaded &&
                            $scope.onFileUploaded(eventParams.fileId);
                        }
                      },
                      function (error) {
                        pubsubService.publish('IMAGEUPLOAD_UPLOAD_FAILED');
                      }
                    );
                },
                (rejected) => {
                  notificationService.showWarningToast(
                    file.name + ' is not an accepted image type.'
                  );
                }
              );
          });

          Promise.all(promises).then(() =>
            uploadFileBatch(files, start + batchSize, batchSize)
          );
        }

        function createDownloadLink(fileId) {
          return fileService.getDownloadLink(fileId);
        }
      }
    ]
  };
});
