angular
  .module('fg')
  .directive('fgFieldCurrency', [
    'fgFieldCurrencyLinkFn',
    function (fgFieldCurrencyLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/currency.ng.html',
        scope: true,
        link: fgFieldCurrencyLinkFn
      };
    }
  ])
  .factory('fgFieldCurrencyLinkFn', [
    'currencyService',
    function (currencyService) {
      return function ($scope, $element, $attrs, ctrls) {
        var form = $scope.form;
        var field = $scope.field;
        var schema = field.schema;

        var unit = schema.customUnits;
        var symbol = '';
        var format = '$##,#.00;-$##,#.00'; // basic no currency number
        var customUnits = {};

        if (schema.customUnits) {
          var temp = currencyService.getCurrencyByCode(schema.customUnits);
          if (temp) {
            customUnits = temp;

            // we escape all the characters because some symbols like RSD
            // uses reserved symbols (the dot for example)
            symbol = temp.symbol
              .split('')
              .map(function (c) {
                return '\\' + c;
              })
              .join('');
            format = symbol + '##,#.00 ' + temp.code;
            format += ';-' + format; //  see http://dojo.telerik.com/IqujA/4
          }
        }

        $scope.options = {
          format: format,
          decimals: 2,
          placeholder: schema.placeholder
        };

        $scope.$watch(
          'form.data[field.schema.name]',
          function (newValue, oldValue, scope) {
            const v1 = currencyService.parseNumber(newValue);
            const v2 = currencyService.parseNumber(oldValue);
            if (v1 != v2 && !isNaN(v1)) {
              initializeProxyModel();
              runForceInitializationHackFlow4843(); /// for cases when signalR updates the page state
            }
          },
          true
        );

        initializeProxyModel();
        runForceInitializationHackFlow4843(); // for cases where a user refreshes a page or opens the page

        $scope.updateActualModel = updateActualModel;

        /**
         * This issue is very annoying. So here is the actual issue ordered from it's direct
         * cause all the way up to Kendo.
         *
         * Here we go:
         *
         *  1.  For currency, we used a kendo angular wrapped directive named kendo-numeric-text-box
         *  2.  To display the red line and validation errors we use some sort of homebrew validation
         *      system named "custom-input-validation"
         *  3.  "custom-input-validation" heavily uses angular's ng-required validation system
         *  4.  The way the kendo-numeric-text-box works is that it generates a dummy element as a proxy
         *      for the way we input numbers (this is visible when you inspect element)
         *
         *      This element can also be accessed via $(select).data('kendoNumericTextBox')._text
         *
         * 5.  This proxy element does not get any of the attributes that we defined, not the ng-required stuff
         *      nor the custom-input-validation. It is not even an angular element, but a raw js element.
         *      So whatever validation occurs, it looks like the validation isn't working. Except...
         *
         *  6.  When you select/focus the actual element, the proxy element is swapped with our initial defined
         *      element, so suddenly the validations come out. But when the blurr happens, its swapped back
         *      to the proxy element.
         *
         *  7.  Since we don't really have time to investigate this fully, the easiest solution would be to manually
         *      display the validation-red lines based on  our custom-input-validation. But for that to work
         *      we have to make sure that the $digest cycle doesnt run. See setTimouet below
         *
         *                                                                      - Cassey
         */
        $scope.updateKendoGeneratedInput = function (id) {
          var $state = $scope.form.state[$scope.field.schema.name];

          var $element = $('#' + id);
          var $inputs = $element.add(
            $element.data('kendoNumericTextBox')._text
          );

          /*
           * This is a very bad solution. I apologize to all the future
           * devs who have somehow wound up here in this currency directive.
           *
           * For here do NOT use the
           * $timeout, because we want the $digest not to be triggered
           * since we are attempting to override the mess of behaviors that
           * we've introduced.
           *
           * If we trigger the $digest, it will reinsert the ng-dirty, and
           * ng-invalid classes again
           *                                      - Cassey
           */
          setTimeout(function () {
            if ($state.$invalid) {
              $inputs.addClass('ng-dirty');
              $inputs.addClass('ng-invalid');
            } else {
              $inputs.removeClass('ng-dirty');
              $inputs.removeClass('ng-invalid');
            }
          }, 150);
        };

        function updateActualModel(proxyValue) {
          if (customUnits) {
            var val = currencyService.parseNumber(proxyValue);
            if (schema.customUnits) {
              form.data[schema.name] = currencyService.toCurrency(
                val,
                schema.customUnits
              );
            } else {
              form.data[schema.name] = val;
            }
          }
          return true; // return true please because currency.ng.html ng-change is an expression
        }

        function runForceInitializationHackFlow4843() {
          if (schema.defaultPreviousStepId && form.data[schema.name] != null) {
            // hack fix for FLOW-4843, the updateActualFlowModel which formats the
            // currency as the thing to send to the BE isnt called if the field isnt
            // changed and if they submit directly without modifying it never gets called
            // so we manually call it once here.
            updateActualModel(form.data[schema.name]);
          }
        }

        function initializeProxyModel() {
          var value = currencyService.parseNumber(
            $scope.form.data[schema.name]
          );
          if (!isNaN(value)) {
            $scope.proxyModel = value;
          }
        }
      };
    }
  ]);
