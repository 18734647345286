angular
  .module('fg')
  .directive('fgEdit', [
    'workflowStore',
    'APP_CONFIG',
    'flowinglyConstants',
    function (workflowStore, APP_CONFIG, flowinglyConstants) {
      return {
        priority: 100,
        require: 'fgSchema',
        restrict: 'AE',
        scope: {
          schema: '=?fgSchema',
          fieldType: '=?fgFieldType',
          nodeKey: '=?fgNodeKey',
          nodeId: '=?fgNodeId'
        },
        replace: true,
        controller: 'fgEditController as editCtrl',
        templateUrl: 'angular-form-gen/edit/edit.ng.html',
        link: function ($scope, $element, $attrs, schemaCtrl) {
          if ($scope.schema === undefined) {
            $scope.schema = {};
          }

          schemaCtrl.model($scope.schema);

          // Inserts an approval rule element when a task is an Approval Rule
          // i.e. taskType = 8

          var approvalRuleTemplate = null;
          var fields = schemaCtrl.model().fields || [];
          var hasApprovalControl = false;

          angular.forEach(fields, function (field) {
            if (field.type === 'approvalrule') {
              hasApprovalControl = true;
            }
          });

          if (
            APP_CONFIG?.allowProcessMap &&
            APP_CONFIG?.enableModelerFlowModelTypeSelection &&
            APP_CONFIG?.enableMappingFieldsOnly &&
            workflowStore?.getFlowModelType() ===
              flowinglyConstants.flowModelType.PROCESS_MAP
          ) {
            $scope.templates = $scope.templates.filter(({ type }) =>
              [
                flowinglyConstants.formFieldType.INSTRUCTION,
                flowinglyConstants.formFieldType.ATTACH_DOCUMENT,
                flowinglyConstants.formFieldType.CHECKBOX,
                flowinglyConstants.formFieldType.TASK_LIST
              ].includes(type)
            );
          }

          angular.forEach($scope.templates, function (template) {
            if (template.type === 'approvalrule') {
              approvalRuleTemplate = template;
            }
          });

          if ($scope.fieldType) {
            if ($scope.fieldType === 8) {
              if (!hasApprovalControl && approvalRuleTemplate !== null)
                schemaCtrl.addField(approvalRuleTemplate);
            }
            if ($scope.fieldType === 0) {
              if (hasApprovalControl && approvalRuleTemplate !== null) {
                schemaCtrl.removeField(approvalRuleTemplate);
              }
            }
          }

          $scope.schemaCtrl = schemaCtrl;
        }
      };
    }
  ]);
